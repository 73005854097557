import React from 'react';
import './styles/App.css';
import MobileStoreButton from 'react-mobile-store-button';

const path = window.location.pathname;
const type = path.split('/')[1];
const urlParams = new URLSearchParams(window.location.search);
const params = Object.fromEntries(urlParams.entries());
const title = type === 'share-profile' ? `Follow ${params['user']}` : 'Willow Redirect';

window.document.title = title;

const App = () => (
    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', paddingTop: 20 }}>
        {path.split('/')[1] === 'share-profile'
            ? <p>Download or update Willow to follow {params['user']}</p>
            : <p>Download or update Willow to follow this link</p>}
        <MobileStoreButton
            store="ios"
            url={'https://apps.apple.com/bg/app/willow-protocols-for-life/id1559364055#?platform=iphone'}
            linkProps={{ title: 'iOS Store Button' }}
        />
        <div></div>
        <MobileStoreButton
            store="android"
            url={'https://play.google.com/store/apps/details?id=com.whetstone.pinecone'}
            linkProps={{ title: 'Google Play Store Button' }}
            width={288}
            height={115}
        />
    </div>
);

export default App;